
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f5f5;
}
